<template>
	<div>
		<el-card>
			<div slot="header" class="clearfix">
				<span class="title">建议</span>
			</div>
			<div>
				<el-form ref="form" :model="form" :rules="rules" label-width="100px">
					<el-form-item label="学校机构：">
						<el-checkbox-group
							v-model="form.checkedDept"
							@change="handleCheckedDeptChange"
						>
							<el-checkbox
								v-for="item in form.deptList"
								:label="item.name + '=' + item.id"
								:key="item.id"
								>{{ item.name }}</el-checkbox
							>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="建议：" prop="desc">
						<el-input
							type="textarea"
							v-model="form.desc"
							:rows="5"
						></el-input>
					</el-form-item>
					<el-form-item>
						<el-button
							type="primary"
							@click="onSubmit('form')"
							:disabled="isAble == -1"
							>立即提交</el-button
						>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
import { getDeptList_api, saveAdvice_api } from '@/api/user.js'

export default {
	inject: ['reload'],
	data() {
		return {
			isAble: 'false',
			form: {
				deptList: [],
				checkedDept: [],
				desc: '',
			},
			rules: {
				desc: [
					{
						required: true,
						message: '请填写建议内容',
						trigger: 'blur',
						max: 300,
					},
				],
			},
		}
	},

	methods: {
		async getDeptList() {
			let res = await getDeptList_api({})

			if (res.code == 200) {
				if (res.data.length > 0) {
					this.form.deptList = res.data
				}
			}
		},
		handleCheckedDeptChange(val) {
			// console.log(JSON.stringify(val))
		},

		async onSubmit(formName) {
			this.isAble = '-1'

			let arr = this.form.checkedDept
			let deptId = ''
			let deptName = ''
			//let {flag}=await  this.checkFormFlag(formName,flag)
			this.$refs[formName].validate((valid) => {
				if (valid) {
					for (let i = 0; i < arr.length; i++) {
						let arrStr = arr[i].split('=')
						if (i < arr.length - 1) {
							deptName += arrStr[0] + ','
							deptId += arrStr[1] + ','
						} else {
							deptName += arrStr[0]
							deptId += arrStr[1]
						}
					}
					let params = {
						deptId: '',
						deptName: '',
						content: '',
					}
					params.deptId = deptId
					params.deptName = deptName
					params.content = this.form.desc

					saveAdvice_api(params).then(
						(res) => {
							this.$message({
								type: 'info',
								message: `提交成功`,
							})
							this.reload()
						},
						(error) => {
							this.$message({
								type: 'info',
								message: `提交失败`,
							})
						}
					)
				}
				return false
			})

			this.isAble = 'false'
		},
	},
	created() {
		this.getDeptList()
	},
}
</script>

<style scoped></style>
